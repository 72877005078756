export function useCanViewTrustReport(id: any) {
  const profile = useProfile()
  const claims = useClaims()

  // if (!profile.value?.roleId)
  //   return false

  // if ([1, 2, 4].includes(profile.value.roleId))
  //   return true

  // if (profile.value.roleId === 3) {
  //   return claims.value.find((claim) => {
  //     return [
  //       claim.summary?.application.id,
  //       claim.summary?.application.uid,
  //       claim.summary?.matchApplication?.id,
  //       claim.summary?.matchApplication?.uid,
  //     ].includes(id)
  //   }) !== undefined
  // }

  // return false
  return _useCanViewTrustReport(id, profile.value?.roleId, claims.value)
}

export function _useCanViewTrustReport(id: any, roleId: number | undefined, claims: AppGuide.Devs.Claim[] | undefined) {
  switch (roleId) {
    case 1:
    case 2:
    case 4:
      return true
    case 3:
      return claims?.find((claim) => {
        return [
          claim.summary?.application.id,
          claim.summary?.application.uid,
          claim.summary?.matchApplication?.id,
          claim.summary?.matchApplication?.uid,
        ].includes(id)
      }) !== undefined
  }

  return false
}
